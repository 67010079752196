<template>
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-6 post_view">

                <div class="accordion accordion-flush" id="accordionFlushExample2">
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingOne">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                data-bs-target="#flush-getpost" aria-expanded="false" aria-controls="flush-getpost">
                                Post view
                            </button>
                        </h2>
                        <div id="flush-getpost" class="accordion-collapse collapse show"
                            aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample2">
                            <div class="accordion-body">
                                {{ product.status }}<br>
                                {{ product.title }}<br>
                                {{ product.slug }}<br>
                                <b>Price: </b>
                                <span v-for="postmoney in product.money" :key="postmoney.id">{{ $postmoney.country.type }} {{ $postmoney.price }}</span>
                                <br>
                                <div v-html="product.content_full"></div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
            <div class="col-sm-6 col-lg-6 post_view">
                <div class="accordion accordion-flush box_body" id="accordionFlushExample">
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingOne">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#flush-postprivate" aria-expanded="false"
                                aria-controls="flush-collapseOne">
                                Post private
                            </button>
                        </h2>
                        <div id="flush-postprivate" class="accordion-collapse collapse"
                            aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body">
                                <form class="col-md-12" action="/shopnew/posts/save/<?php echo product.id ?>"
                                    method="POST">

                                    <div class="mb-3">
                                        <label for="exampleInputEmail1" class="form-label">content_small</label>
                                        <textarea type="text" id="content_small" class="form-control input-lg"
                                            placeholder="Text Here" name="content_small"
                                            rows="10" v-model="product.content_small"></textarea>
                                    </div>

                                    <button type="submit" class="btn btn-primary bnt_submit"
                                        id="bnt_submit">Save</button>
                                    <button type="button" class="btn btn-primary"
                                        v-on:click="btn_copy_post_small()">Copy</button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingOne">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseOne" aria-expanded="false"
                                aria-controls="flush-collapseOne">
                                Post
                            </button>
                        </h2>
                        <div id="flush-collapseOne" class="accordion-collapse collapse"
                            aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body">
                                <form class="col-md-12" action="/shopnew/posts/save/<?php echo product.id ?>"
                                    method="POST">

                                    <div class="mb-3">
                                        <label for="exampleInputEmail1" class="form-label">Title</label><br>
                                        <input type="text" class="form-control" id="exampleInputEmail1" name="title"
                                            :value="product.title">
                                    </div>

                                    <div class="mb-3">
                                        <label for="exampleInputEmail1" class="form-label">Title small</label><br>
                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                            name="title_small" :value="product.title_small">
                                    </div>

                                    <div class="mb-3">
                                        <label for="exampleInputEmail1" class="form-label">Slug - 225</label><br>
                                        <textarea type="text" class="form-control input-lg" placeholder="Text Here"
                                            name="slug" rows="5" v-model="product.slug"></textarea>
                                    </div>

                                    <div class="mb-3">
                                        <label for="exampleInputEmail1" class="form-label">metaDescription</label>

                                        <textarea type="text" class="form-control input-lg" placeholder="Text Here"
                                            name="metaDescription" v-model="product.metaDescription"></textarea>

                                        <div id="emailHelp" class="form-text">We'll never share your email with anyone
                                            else.</div>
                                    </div>

                                    <div class="mb-3">
                                        <label for="exampleInputEmail1" class="form-label">code_product</label>
                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" name="code_product"
                                            :value="product.number_post">
                                        <div id="emailHelp" class="form-text">We'll never share your email with anyone
                                            else.</div>
                                    </div>

                                    <div class="mb-3">
                                        <label for="exampleInputEmail1" class="form-label">content_full</label>
                                        <textarea type="text" class="form-control input-lg" placeholder="Text Here"
                                            name="content_full" rows="20" v-model="product.content_full"></textarea>
                                    </div>

                                    <div class="mb-3">
                                        <label for="exampleInputEmail1" class="form-label">colors</label>
                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" name="colors" :value=" product.colors">
                                        <div id="emailHelp" class="form-text">We'll never share your email with anyone
                                            else.</div>
                                    </div>

                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" name="status"
                                            id="flexRadioDefault1" value="DRAFT" v-model="product.status" />
                                        <label class="form-check-label" for="flexRadioDefault1">
                                            DRAFT
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" name="status"
                                            id="flexRadioDefault2" value="PUBLISHED" v-model="product.status" />
                                        <label class="form-check-label" for="flexRadioDefault2">
                                            PUBLISHED
                                        </label>
                                    </div>
                                    <button type="submit" class="btn btn-primary bnt_submit"
                                        id="bnt_submit">Submit</button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingTwo">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseTwo" aria-expanded="false"
                                aria-controls="flush-collapseTwo">
                                Images
                            </button>
                        </h2>
                        <div id="flush-collapseTwo" class="accordion-collapse collapse"
                            aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body">
                                <div v-if="product.image">
                                    <form class="col-md-12" @submit.prevent="submitImage">
                                        <input type="hidden" name="_token" :value="csrfToken">
                                        <label for="exampleInputEmail1" class="form-label">Images</label><br>

                                        <div v-for="(item, key) in product.image" :key="key">
                                            <img :src="item.path" alt="" style="width:auto;height:100px;">
                                            <input class="form-check-input" type="radio" name="statusImage2"
                                                :id="'radioImage' + key" value="DRAFT" v-model="selectedImage"
                                                :checked="item.meta == 1" @click="btnName(item.id, product.id)">
                                            <a :href="`/shopnew/posts/deleteimageproduct/${item.id}`" type="button"
                                                class="btn btn-primary">
                                                X
                                            </a>
                                        </div>

                                        <button type="submit" class="btn btn-primary btn_image_submit"
                                            hidden>Submit</button>
                                    </form>
                                </div>

                                <form class="col-md-12" id="update_image">
                                    <div class="form-group row">
                                        <label for="exampleInputEmail1" class="form-label">Update url image jpg</label>
                                        <div class="col-sm-10">
                                            <textarea type="text" class="form-control input-lg" placeholder="Text Here"
                                                name="image_url"></textarea>
                                        </div>
                                        <div class="col-sm-2">
                                            <button type="button" class="btn btn-primary btn_image_submit"
                                                v-on:click="update_img('{{product.id}}','{{product.slug}}');">Update</button>
                                        </div>
                                    </div>
                                </form>

                                <form class="col-md-12" id="update_image_file">
                                    <div class="input-group">
                                        <input type="file" class="form-control" id="inputGroupFile04" name="file_image"
                                            aria-describedby="inputGroupFileAddon04" aria-label="Upload">
                                        <button class="btn btn-outline-secondary" type="button"
                                            id="inputGroupFileAddon04"
                                            v-on:click="update_img_file('{{product.slug}}','{{product.id}}')">Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingThree">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#flush-money" aria-expanded="false" aria-controls="flush-money">
                                Money
                            </button>
                        </h2>
                        <div id="flush-money" class="accordion-collapse collapse" aria-labelledby="flush-headingThree"
                            data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body">
                                <div v-for="item in product.money" :key="item.id">
                                <form :id="'delete_price_'+item.id">
                                    <div class="row g-3 align-items-end">
                                        <div class="col-sm-7">
                                            <label for="exampleInputEmail1" class="form-label">Price:
                                                <b>{{ $key }}</b></label>
                                            <input type="text" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" name="price_normal"
                                                :value="item.price">
                                        </div>
                                        <div class="col-sm-2">
                                            <label for="exampleFormControlSelect1">Money</label>
                                            <select class="form-control" id="exampleFormControlSelect1"
                                                name="country_id">
                                                <option v-for="(country, index) in product.countries" :key="index"
                                                    :value="country.id">
                                                    {{ country.type }}z
                                                </option>
                                            </select>
                                        </div>
                                        <div class="col-3">
                                            <button type="button" class="btn btn-primary btn_image_submit"
                                                v-on:click="detelete_price('{{product.id}}','{{item.id}}');">X</button>
                                            <button type="button" class="btn btn-primary btn_image_submit"
                                                v-on:click="update_price('{{item.id}}');">V</button>
                                        </div>
                                    </div>
                                    <br>
                                </form>
                                </div>

                                <form id="add_price" class="row g-3 align-items-end">
                                    <div class="col-sm-8">
                                        <label for="exampleInputEmail1" class="form-label">Add price2</label>
                                        <input type="text" class="form-control" name="addprice" value="">
                                    </div>
                                    <div class="col-sm-2">
                                        <label for="exampleFormControlSelect1" class="form-label">Money</label>
                                        <select class="form-control" id="exampleFormControlSelect1" name="country_id">
                                            <option :value="item.id" v-for="item in product.countries" :key="item.id">{{ item.type }}</option>
                                        </select>
                                    </div>
                                    <div class="col-sm-2">
                                        <button type="button" class="btn btn-primary"
                                            v-on:click="add_price_product('{{product.id}}');">Add</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingThree">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#flush-categories" aria-expanded="false"
                                aria-controls="flush-categories">
                                Categories
                            </button>
                        </h2>
                        <div id="flush-categories" class="accordion-collapse collapse"
                            aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body">
                                <form id="category_delete">
                                    <div class="row" v-for="category in product.postCategory" :key="category.id">
                                        <div class="col-3">
                                            <img style="width:auto;height:100px;" :src="category.path" alt="">
                                        </div>
                                        <div class="col">
                                            {{ category.name }} <br>
                                        </div>
                                        <div class="col-2">
                                            <button type="button" class="btn btn-primary btn_image_submit"
                                                v-on:click="category_delete('{{category.id}}','{{product.id}}');">X</button>
                                        </div>
                                    </div>
                                    <br>
                                </form>

                                <form id="add_category">
                                    <label for="exampleFormControlSelect1">Add product category</label>
                                    <div class="row align-items-end">
                                        <div class="col-10">
                                            <select class="form-select" aria-label="Default select example"
                                                name="category_id">
                                                <option :value="category.id" v-for="category in product.categories" :key="category.id">{{ category.name }}</option>
                                            </select>
                                        </div>
                                        <div class="col-2">
                                            <button type="button" class="btn btn-primary btn_image_submit"
                                                v-on:click="add_category('{{product.id}}');">Add</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingThree">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#flush-colors" aria-expanded="false" aria-controls="flush-colors">
                                Colors
                            </button>
                        </h2>
                        <div id="flush-colors" class="accordion-collapse collapse" aria-labelledby="flush-headingThree"
                            data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body">
                                <h2></h2>

                                <form id="color_delete">
                                    <div class="row" v-for="postColor in product.postColors" :key="postColor.id">
                                        <div class="col">
                                            {{ $postColor.id }} |{{ $postColor.name }} | {{ $postColor.status }} |
                                            {{ $postColor.available }} <br>
                                        </div>
                                        <div class="col-2">
                                            <button type="button" class="btn btn-primary btn_image_submit"
                                                v-on:click="color_delete('{{$postColor.id}}','{{product.id}}');">X</button>
                                        </div>
                                    </div>
                                    <br>
                                </form>

                                <form id="color_add">
                                    <div class="row align-items-end">

                                        <div class="col-10">
                                            <label for="exampleInputEmail1" class="form-label">Add product
                                                color</label><br>
                                            <input type="text" class="form-control" id="exampleInputEmail1"
                                                name="colors" :value="product.colors">
                                        </div>
                                        <div class="col-2">
                                            <button type="button" class="btn btn-primary"
                                                v-on:click="colors_update('{{product.id}}');">Update</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingThree">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#flush-ralated" aria-expanded="false" aria-controls="flush-ralated">
                                Related
                            </button>
                        </h2>
                        <div id="flush-ralated" class="accordion-collapse collapse" aria-labelledby="flush-headingThree"
                            data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body">
                                <h2>Related</h2>

                                <form id="add_related_delete">
                                    <div class="row" v-for="item in product.related" :key="item.id">
                                        <div class="col-2">
                                            <div v-for="(image, index) in item.other_post.image" :key="index">
                                                <img v-if="index==0" style="width:auto;height:100px;" :src="image.thumbnail" alt="">
                                            </div>
                                        </div>
                                        <div class="col">
                                            {{ item.other_post.title }} <br>
                                        </div>
                                        <div class="col-2">
                                            <button type="button" class="btn btn-primary btn_image_submit"
                                                v-on:click="add_related_delete('{{item.id}}');">X</button>
                                        </div>
                                    </div>
                                    <br>
                                </form>

                                <form id="add_related">
                                    <label for="exampleFormControlSelect1">Add product related</label>
                                    <div class="row align-items-end">
                                        <div class="col-10">
                                            <select class="form-select" aria-label="Default select example"
                                                name="other_post_id">
                                                <option :value="item.id" v-for="item in product.allposts" :key="item.id">{{ item.title }}</option>
                                            </select>
                                        </div>
                                        <div class="col-2">
                                            <button type="button" class="btn btn-primary btn_image_submit"
                                                v-on:click="add_related('{{product.id}}');">Add</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingThree">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#flush-reviews" aria-expanded="false" aria-controls="flush-reviews">
                                Reviews
                            </button>
                        </h2>
                        <div id="flush-reviews" class="accordion-collapse collapse" aria-labelledby="flush-headingThree"
                            data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body">
                                <h2>Reviews</h2>

                                <form id="add_review_images" class="row g-3 align-items-end">
                                    <div class="mb-3">
                                        <label for="exampleFormControlTextarea1" class="form-label">Images</label>
                                        <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"
                                            name="body"></textarea>
                                    </div>

                                    <div class="col-sm-2">
                                        <button type="button" class="btn btn-primary"
                                            v-on:click="add_review_images('{{product.id}}');">Add</button>

                                    </div>
                                </form>
                                <hr>
                                <form id="add_review" class="row g-3 align-items-end">
                                    <div class="mb-3">
                                        <label for="exampleInputEmail1" class="form-label">Rating</label><br>
                                        <input type="text" class="form-control" id="exampleInputEmail1" name="rating"
                                            value="<?php echo $rating ?>">
                                    </div>
                                    <div class="mb-3">
                                        <label for="exampleFormControlInput1" class="form-label">Name</label>
                                        <input type="text" class="form-control" id="exampleFormControlInput1"
                                            placeholder="name" name="name" :value="product.full_name">
                                    </div>
                                    <div class="mb-3">
                                        <label for="exampleFormControlTextarea1" class="form-label">Body</label>
                                        <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"
                                            name="body"></textarea>
                                    </div>
                                    <div class="mb-3">
                                        <label for="exampleFormControlInput1" class="form-label">Image</label>
                                        <input type="text" class="form-control" id="exampleFormControlInput1"
                                            placeholder="name" name="images">
                                    </div>

                                    <div class="col-sm-2">
                                        <button type="button" class="btn btn-primary"
                                            v-on:click="add_review('{{product.id}}');">Add</button>
                                    </div>
                                </form>

                                <hr>

                                <div class="row" v-for="item in product.review" :key="item.id">
                                    <div class="col-2">
                                        <img style="width:auto;height:100px;" :src="item.images" alt="">
                                    </div>
                                    <div class="col-8">
                                        {{ item.status }} - {{ item.rating }} - {{ item.name }}<br>
                                        {{ item.body }} <br>
                                    </div>
                                    <div class="col-2">
                                        <form id="delete_related">
                                            <button type="button" class="btn btn-primary"
                                                v-on:click="delete_related('{{item.id}}');">Delete</button>
                                        </form>
                                        <a :href="'/shopnew/reviews/edit/'+item.id" type="button"
                                            class="btn btn-primary">Edit</a>
                                        <form id="public_related">
                                            <button type="button" class="btn btn-primary"
                                                v-on:click="public_related('{{item.id}}');">Public</button>
                                        </form>
                                    </div>
                                </div>
                                <br>

                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingThree">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#flush-packs" aria-expanded="false" aria-controls="flush-packs">
                                Packs
                            </button>
                        </h2>
                        <div id="flush-packs" class="accordion-collapse collapse" aria-labelledby="flush-headingThree"
                            data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body">
                                <div v-for="(pack, index) in product.packs" :key="index">
                                <div v-if="pack.packPost">
                                <div class="row">
                                    <div class="col-3">
                                        <img style="width:auto;height:100px;"
                                            :src="pack.packPost.imageOnly.thumbnail" alt="">
                                    </div>
                                    <div class="col">
                                        <p>
                                            <a :href="$pack.packPost.id" target="_blank" rel="noopener noreferrer">{{ $pack.packPost['title'] }}</a> <br>
                                            <b>Status: </b>{{ $pack.status }} - S/. {{ $pack.price }} <br>
                                            <b>Color: </b>{{ $pack.packPost['colors'] }} <br>
                                            <b>Precio: </b><br>
                                            <span v-for="price in pack.packPost.money" :key="price.id">{{ $price.price }} <br></span>
                                        </p>
                                    </div>
                                    <div class="col-2">
                                        <button type="button" class="btn btn-primary btn_image_submit"
                                            v-on:click="pack_delete('{{$pack.id}}','{{product.id}}');">X</button>
                                    </div>
                                </div>
                                <form :id="'pack_update_'+pack.id">
                                    <div class="row">
                                        <div class="col-2">
                                        </div>
                                        <div class="col-8">
                                            <div class="row">
                                                <div class="col-2">
                                                    <label for="exampleInputEmail1" class="form-label">Price</label><br>
                                                </div>
                                                <div class="col-10">
                                                    <input type="text" class="form-control" id="exampleInputEmail1"
                                                        name="price" :value="pack.price">
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-2">
                                                    <label for="exampleFormControlInput1"
                                                        class="form-label">Status</label>
                                                </div>
                                                <div class="col-10">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="radio" name="status"
                                                            id="flexRadioDefault1" value="DRAFT" v-model="pack.status">
                                                        <label class="form-check-label" for="flexRadioDefault1">
                                                            DRAFT
                                                        </label>
                                                    </div>
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="radio" name="status"
                                                            id="flexRadioDefault2" value="PUBLISHED"
                                                            v-model="pack.status">
                                                        <label class="form-check-label" for="flexRadioDefault2">
                                                            PUBLISHED
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-2">
                                            <button type="button" class="btn btn-primary btn_image_submit"
                                                v-on:click="pack_update('{{$pack.id}}')">V</button>
                                        </div>
                                    </div>
                                </form>
                                </div>
                                <div v-else>{{ pack }}</div>
                                </div>

                                <form id="add_pack">
                                    <label for="exampleFormControlSelect1">Add product pack</label>
                                    <div class="row align-items-end">
                                        <div class="col-10">
                                            <select class="form-select" aria-label="Default select example"
                                                name="other_post_id">
                                                <option :value="item.id" v-for="item in product.allposts" :key="item.id">{{ item.title }} - {{ item.status }}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="col-2">
                                            <button type="button" class="btn btn-primary btn_image_submit"
                                                v-on:click="add_pack('{{product.id}}');">Add</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    
}
</script>
<style scoped>
    
</style>